<template>
  <div style="height: inherit">
    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                {{ totalEntities }} results found
              </div>
            </div>
            <div class="view-options d-flex">
              <b-button
                style="margin-right: 10px"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="showNewEntityModal()"
                >New Entity</b-button
              >
       

             

            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="filters.q"
              placeholder="Search Product"
              class="search-product"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <!-- Prodcuts -->
    <section :class="itemView">
      <b-card
        v-for="entity in curretEntities"
        :key="entity.id"
        class="ecommerce-card"
        no-body
      >
    

        <!-- Product Details -->
        <b-card-body>
          <h6 class="item-name">
            <b-link
              class="text-body"
              :to="{
                name: 'apps-template-editor-list',
                query: { namespace: entity.id, templateTypes: 'ENTITY', destinationOrganization: entity.organization },
              }"
            >
              {{ entity.name }}
            </b-link>
            <b-card-text class="item-company">
              By
              <b-link class="ml-25">
                {{ entity.organization }}
              </b-link>
            </b-card-text>
          </h6>
          <b-card-text class="item-description">
            {{ entity.description }}
          </b-card-text>
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <b-button
            variant="light"
            tag="a"
            class="btn-wishlist"
            @click="$refs['clone'].showModal(entity)"
          >
            <span>Clone</span>
          </b-button>
          <b-button
            variant="primary"
            tag="a"
            class="btn-wishlist"
            @click="$refs['assign'].showModal([entity])"
          >
            <span>Assign</span>
          </b-button>
          <b-button
            variant="relief-warning"
            class="btn-wishlist"
            @click="showNewEntityModal(entity)"
          >
            <span>Edit</span>
          </b-button>
          <b-button
            variant="danger"
            tag="a"
            class="btn-cart"
            @click="deleteSelectedEntities([entity])"
          >
            <span>Delete</span>
          </b-button>
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="totalEntities"
            :per-page="filters.limit"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>
    <portal
     
      to="content-renderer-sidebar-detached-left"
    >
      <div >
        <entities-left-filter-sidebar
          :filters="filters"
          :filter-options="filterOptions"
          :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        />
      </div>
    </portal>
    <b-modal
      id="modal-prevent-closing"
      ref="new-entity-modal"
      :title="createOrUpdate + ' Entity'"
      :ok-title="createOrUpdate"
      centered
      cancel-variant="outline-secondary"
      @hidden="resetNewEntityModal"
      @ok="newEntityeOk"
    >
      <form ref="form" @submit.stop.prevent="newEntitySubmit">
        <b-form-group
          :state="newEntityState"
          label="Entity Name"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            @input="onEntityNameInput"
            v-model="newEntity.name"
            :state="newEntityState"
            required
          />
        </b-form-group>
        <b-form-group
          :state="newEntityState"
          label="Entity Description"
        >
          <b-form-textarea
            v-model="newEntity.description"
            :state="newEntityState"
            required
          />
        </b-form-group>
         <b-form-group
          v-if="!newEntity.id"
          :state="newEntityState"
          label="Entity Keys"
          label-for="entity-fields-input"
          invalid-feedback="Name is required"
        >
          <b-form-tags
            v-model="newEntity.entityKeys"
            input-id="entity-fields-input"
            class="mb-2"
          />
       
        </b-form-group>
         <b-form-group
          v-else
          :state="newEntityState"
          label="Entity Keys"
          label-for="entity-fields-input"
          invalid-feedback="Name is required"
        >
          <b-card>
            
            <b-form-input v-model="entityKey.text" :key="entityKey.value" v-for="entityKey in newEntity.entityKeys" />
          </b-card>
       
        </b-form-group>
        <b-form-group
          :state="newEntityState"
          label="Entity Fields"
          label-for="entity-fields-input"
          invalid-feedback="Name is required"
        >
       
          <b-form-tags
            v-model="newEntity.fields"
            input-id="entity-fields-input"
            class="mb-2"
          />
        </b-form-group>
    <b-form-group label="Build custom id">
      <b-form-tags
        v-model="newEntity.customId"
        no-outer-focus
      >
        <template v-slot="{ tags, disabled, addTag, removeTag }">
          <ul
            v-if="tags.length > 0"
            class="list-inline d-inline-block mb-1"
          >
            <li
              v-for="tag in tags"
              :key="tag"
              class="list-inline-item"
            >
              <b-form-tag
                :title="tag"
                :disabled="disabled"
                variant="primary"
                @remove="removeTag(tag)"
              >
                {{ tag }}
              </b-form-tag>
            </li>
          </ul>

          <b-dropdown
            size="sm"
            variant="outline-secondary"
            block
            menu-class="w-100"
          >
            <template v-slot:button-content>
              <b-icon icon="tag-fill" /> Choose the ids
            </template>
            <b-dropdown-form @submit.stop.prevent="() => {}">
              <b-form-group
                label-for="tag-search-input"
                label="Search tags"
                label-cols-md="auto"
                class="mb-0"
                label-size="sm"
                :description="searchDesc"
                :disabled="disabled"
              >
                <b-form-input
                  id="tag-search-input"
                  v-model="search"
                  type="search"
                  size="sm"
                  autocomplete="off"
                />
              </b-form-group>
            </b-dropdown-form>
            <b-dropdown-divider />
            <b-dropdown-item
              v-for="option in availableOptions"
              :key="option"
              @click="onOptionClick({ option, addTag })"
            >
              {{ option }}
            </b-dropdown-item>
            <b-dropdown-text v-if="availableOptions.length === 0">
              There are no tags available to select
            </b-dropdown-text>
          </b-dropdown>
        </template>
      </b-form-tags>
    </b-form-group>
      </form>
    </b-modal>

    <assign-modal ref="assign" @on-assign="assignHandleSubmit" :organizations="filterOptions.organizations"></assign-modal>
    <clone-modal ref="clone" @on-clone="cloneHandleSubmit"></clone-modal>

    <div id="contilt-temp">
    </div>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
  BFormGroup,
  BFormTags,
  BFormTextarea,
  BDropdownForm,BDropdownDivider,BFormTag,BDropdownText, BIcon
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { watch } from "@vue/composition-api";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import {
  useFiltersSortingAndPagination,
  useUi,
  useRemoteData,
} from "./useEntity";
import organizationStoreModule from "../../organization/organizationStoreModule";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import EntitiesLeftFilterSidebar from "./EntitiesLeftFilterSidebar.vue";
import { ContiltEditor } from '../../template/contilt-editor'
import axios from "@axios";
import AssignModal from "../../../modals/AssignModal"
import CloneModal from "../../../modals/CloneModal"
import router from '@/router';
export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BFormGroup,
    EntitiesLeftFilterSidebar,
    BFormTags,
    BFormTextarea,
    AssignModal,
    CloneModal,
    BDropdownForm,BDropdownDivider,BFormTag,BDropdownText,BIcon
  },
  data(){
    return{
      nodeTypeList: null,
      createOrUpdate: "Create",
      search: "",
      newEntity: {
        name: "",
        description: "",
        fields: [],
        entityKeys: [],
        customId: []
      },
      newEntityState: null,
      filterOptions: {
        organizations: []
      },
      toAssign: null,
      assignOrganizationsState: null,
    }
  },
  computed: {
    curretEntities() {
     
      return this.entities;
    },
    criteria() {
      // Compute the search criteria
      return this.search.trim().toLowerCase()
    },
    availableOptions() {
      const { criteria } = this
      let _this = this;
      // Filter out already selected options
      const options = this.newEntity.fields.filter(opt => _this.newEntity.customId.indexOf(opt) === -1)
      if (criteria) {
        // Show only options that match criteria
        return options.filter(opt => opt.toLowerCase().indexOf(criteria) > -1)
      }

      // Show all options available
      return options
    },
        searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return 'There are no tags matching your search criteria'
      }
      return ''
    },
  },
  methods: {
    onOptionClick({ option, addTag }) {
      addTag(option)
      this.search = ''
    },
    assignHandleSubmit(data) {
    
      axios
        .post('/entities/assign', {entities: data.toAssign, destinationOrganization: data.destinationOrganization})
        .then(() => this.fetchTheEntities())
        .catch(error => console.log(error))
     
     
    },

    cloneHandleSubmit(data) {
    
      axios
        .post(`/entities/clone/${data.id}`, {name: data.name})
        .then(() => this.fetchTheEntities())
        .catch(error => console.log(error))
     
     
    },
    onEntityNameInput(){
      if(!this.newEntity.id){
        this.newEntity.entityKeys.push(this.newEntity.name);
        this.newEntity.entityKeys[0] = this.newEntity.name;
      }

    },

    showNewEntityModal(entity) {
      debugger;
      if(entity){

        this.createOrUpdate = "Update";
          axios
            .get(`/entity-keys`, {
              params: {
                selectedFields: "id name",
                entityId: entity.id,
                organizations: this.filters.organization ,
                limit: 100
              },
            })
            .then((res) => {
               this.newEntity = entity;
               this.newEntity.entityKeys = res.data.results.map((x) => {
                return {
                  text: x.name,
                  value: x.id
                };
              });
              
            });
    
       
      }else {
        this.createOrUpdate = "Create"
      }
     this.$refs["new-entity-modal"].show();
    },
    checkNewEntityFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.newEntityState = valid;
      return valid;
    },
    resetNewEntityModal() {
      this.newEntity = {
        name: "",
        description: "",
        fields: [],
        entityKeys: [],
        customId: []
      }
      this.newEntityState = null;
    },
    newEntityeOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.newEntitySubmit();
      this.resetNewEntityModal();
    },
    newEntitySubmit() {
      // Exit when the form isn't valid
      if (!this.checkNewEntityFormValidity()) {
        return;
      }
      const data = JSON.parse(JSON.stringify(this.newEntity));
      if(!data.entityKeys || data.entityKeys.length ==0){
        data.entityKeys = [data.name]
      }
      data.organization = this.filters.organization 
      let _this = this;
      if(data.id){
        if(data.entityKeys){
          data.entityKeys.forEach(element => {
            axios.patch(`entity-keys/${element.value}`, {name: element.text})
          });
        }
        
        axios
        .patch(`/entities/${data.id}`, data)
        .then(async (response) => {
         
          const contiltEditor = new ContiltEditor(document.getElementById("contilt-temp"), _this.nodeTypeList, {});
          contiltEditor.addDefaultEntityTemplatePage(data.fields, response.data[0].id)
          const template = {
            name: "Default",
            namespace: data.id,
            documentation: {
              description: data.description
            },
            inputs: contiltEditor.getInputsStructure(),
            outputs: contiltEditor.getOutputsStructure(),
            templateTypes: ["ENTITY"], templateJson: contiltEditor.getNodes()
          }
          await axios.post("/templates/updateTemplates", {query: {namespace: data.id} ,data: template}, { headers: { "Content-Encoding": "gzip" }})
          this.fetchTheEntities()
        })
        .catch((error) => console.log(error));
      }
      else {
        axios
        .post("/entities", data)
        .then(async (response) => {
         
          const contiltEditor = new ContiltEditor(document.getElementById("contilt-temp"), _this.nodeTypeList, {});
          contiltEditor.addDefaultEntityTemplatePage(data.fields, response.data.entityKeys[0])
          const template = {
            name: "Default",
            namespace: response.data.entityId,
            documentation: {
              description: data.description
            },
            inputs: contiltEditor.getInputsStructure(),
            outputs: contiltEditor.getOutputsStructure(),
            templateTypes: ["ENTITY"], templateJson: contiltEditor.getNodes()
          }
          await axios.post("/templates", template, { headers: { "Content-Encoding": "gzip" }})
          this.fetchTheEntities()
        })
        .catch((error) => console.log(error));
      }
      

      this.$nextTick(() => {
        this.$refs["new-entity-modal"].toggle("#toggle-btn");
      });
    },


    deleteSelectedEntities(toDelete) {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure that you want to delete the selected entities [${toDelete.map(x=>x.name)}]?`,
          {
            title: "Delete Entities",
            size: "sm",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.deleteEntities(
              toDelete.map((x) => x.id),
            ).then((res) => {
              this.fetchTheEntities();
              console.log(res);
            });
          }
        });
    },
  },
  created() {
    let _this = this;
    axios.get(`/template-nodes`).then(nodeTypeListRes => {
      _this.nodeTypeList = nodeTypeListRes.data.nodeTypeList
    });

    store.dispatch("app-organization/fetchOrganizations",{selectedFields: "id name", limit: 1000}).then((response) => {
      const organizations = response.data.results.map((x) => {
        return {
          id: x.id,
          value: x.name,
        };
      });
      
      _this.filterOptions.organizations = organizations
      if(!_this.filters.organization){
        _this.filters.organization = organizations[0].id
      }else {
        this.fetchTheEntities()
      }
      
  
    });
  },
  setup() {
    function startLoading() {
      const appLoading = document.getElementById("loading-bg");
      appLoading.style.display = "contents";
    }
    function stopLoading() {
      const appLoading = document.getElementById("loading-bg");
      appLoading.style.display = "none";
    }

    onUnmounted(() => {
      if (store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME))
        store.unregisterModule(ORGANIZATION_APP_STORE_MODULE_NAME);
    });

    const ORGANIZATION_APP_STORE_MODULE_NAME = "app-organization";
    if (!store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME))
      store.registerModule(
        ORGANIZATION_APP_STORE_MODULE_NAME,
        organizationStoreModule
      );

    const { filters } =
      useFiltersSortingAndPagination(router.currentRoute.query);

    const { itemView, itemViewOptions, totalEntities } = useUi();

    const {
      entities,
      fetchEntities,
      deleteEntities,
    } = useRemoteData();

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    const fetchTheEntities = () => {
      startLoading();
      const orgs = [];
      if (filters.value.organization) {
        orgs.push(filters.value.organization);
      }
      const query = {
        q: filters.value.q,
        organizations: orgs.join(","),
    
        page: filters.value.page,
        limit: filters.value.limit
      }

      router.replace({
        params: router.currentRoute.params,
          query: {...router.currentRoute.query, ...filters.value, ...query}
      });
      fetchEntities(query).then((response) => {
        stopLoading();
        entities.value = response.data.results;
        totalEntities.value = response.data.totalResults;
      });
    };

    
    watch(
      [filters],
      () => {
        fetchTheEntities();
      },
      {
        deep: true,
      }
    );

    return {
      // useShopFiltersSortingAndPagination
      filters,

      // useShopUi
      itemView,
      itemViewOptions,
      totalEntities,
      deleteEntities,
      // useShopRemoteData
      entities,
      fetchTheEntities,
      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
  #contilt-temp {
    display: none;
     
  }
    
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }

}


</style>
