<template>
  <b-modal
    no-close-on-backdrop
    id="modal-prevent-closing"
    ref="assign-modal"
    title="Assign templates"
    ok-title="Assign"
    centered
    cancel-variant="outline-secondary"
    @hidden="assignResetModal"
    @ok="assignHandleOk"
  >
    <form ref="assignform" @submit.stop.prevent="assignHandleSubmit">
      <b-form-group
        :state="assignOrganizationsState"
        label="Target organization"
        label-for="name-input"
        invalid-feedback="Organization is required"
      >
        <v-select
          v-model="assignOrganizations"
          label="value"
          :reduce="(option) => option.id"
          :options="organizations"
        />
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import vSelect from 'vue-select';
import {
    BModal,
  BFormGroup,
} from "bootstrap-vue";
export default {
    props: ["organizations"],
    components: {vSelect, BModal, BFormGroup},
  data() {
    return {
        assignOrganizationsState: null,
        assignOrganizations: null,
        toAssign: null
    }
  },
  methods: {
    showModal(toAssign) {
      this.toAssign = toAssign.map((x) => x.id);

      this.$refs["assign-modal"].show();
    },
    assignCheckFormValidity() {
      const valid = this.$refs.assignform.checkValidity();
      this.assignOrganizationsState = valid;
      return valid;
    },
    assignResetModal() {
      this.assignOrganizations = null;
      this.assignOrganizationsState = null;
      this.toAssign = null;
    },
    assignHandleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.assignHandleSubmit();
      this.assignResetModal();
    },
    assignHandleSubmit() {
      // Exit when the form isn't valid
      if (!this.assignCheckFormValidity()) {
        return;
      }
      this.$emit('on-assign', {
        toAssign: this.toAssign,
        destinationOrganization: this.assignOrganizations,
      })
    

      this.$nextTick(() => {
        this.$refs["assign-modal"].toggle("#toggle-btn");
      });
    },
  },
};
</script>