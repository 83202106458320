import { ref } from '@vue/composition-api'
import store from '@/store'

export const useFiltersSortingAndPagination = (data) => {
 
  if(!data){
    data = {};
  }

  const filters = ref({
    q: data.q || '',
    organization: data.organization || null,
    page: data.page? parseInt(data.page): 1,
    limit: data.limit ? parseInt(data.limit) : 10,
 
  })



  return {
    // Filter
    filters,
    // Sort

  }
}

export const useUi = () => {
  const itemView = 'grid-view'
  const itemViewOptions = [
    { icon: 'GridIcon', value: 'grid-view' },
    { icon: 'ListIcon', value: 'list-view' },
  ]

  // Pagination count <= required by pagination component
  const totalEntities = ref(null)

  return {
    itemView,
    itemViewOptions,
    totalEntities,
  }
}

export const useRemoteData = () => {
  const entities = ref([])
  const fetchEntities = (...args) => store.dispatch('app-entity/fetchEntities', ...args)
  const deleteEntities = (...args) => store.dispatch('app-entity/deleteEntities', ...args)

  return {
    entities,
    fetchEntities,
    deleteEntities
  }
}
