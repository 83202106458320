import Drawflow from "@/libs/drawflow/drawflow";
import axios from "@axios";
export class ContiltEditor {
    editor
    nodes
    nodeTypeList
    nodeTypeById
    selectedNodeId
    constructor(elementId, nodeTypeList, callbacks) {
        let self = this;
        this.nodeTypeList = nodeTypeList;
        this.nodeTypeById = {};
        this.nodeTypeList.forEach((currNodeType) => {
            self.nodeTypeById[currNodeType.nodeType] = currNodeType;
        });
        this.nodes = [];
        this.selectedNodeId = null;
        
        const editor = new Drawflow(elementId);
        this.editor = editor;
        editor.reroute = true;
        editor.reroute_fix_curvature = true;
        editor.force_first_input = false;

        editor.on("nodeSelected", function (node_id) {
            self.selectedNodeId = node_id;
            if(callbacks && callbacks.nodeSelected){
                callbacks.nodeSelected(node_id)
            }
        });

        editor.on("nodeUnselected", function () {
            self.selectedNodeId = null;
        });
        editor.on("nodeCloned", function (node_id) {
            const clonedNode = editor.getRefNodeFromId(node_id);
            clonedNode.configItemsByNames = {};
            clonedNode.configItems.forEach(x=> {
              clonedNode.configItemsByNames[x.name] = x;
            })
            self.nodes.push(clonedNode);
            
            
        });

        editor.on("nodeRemoved", function (id) {
            for (let i = 0; i < self.nodes.length; i++) {
              if (self.nodes[i].id == id) {
                self.nodes.splice(i, 1);
              }
            }
        });
        editor.start();
    }
    destroy(){
      this.editor = null;
      this.nodes = [];
      this.nodeTypeList = null;
      this.nodeTypeById = null;
      this.selectedNodeId = null;
      
      
      
      
    }
    addDefaultEntityTemplatePage(inputs, entityKeyId){
        let self = this;
        const inputsVars = [];
        let additional_pos_x = 500;
        let additional_pos_y = 200;
        const inputNodes = [];
        inputs.forEach(x => {
            const inputName = (typeof x == "string") ? x : x.name;
            inputsVars.push(inputName);
            const node = self.addNodeByNodeTypeName("inputNode", x.pos_x || additional_pos_x, x.pos_y || additional_pos_y);
            additional_pos_y += 80;
            node.configItemsByNames["variableName"].value = inputName
            node.configItemsByNames["label"].value = inputName
            node.configItemsByNames["documentation"].value = inputName
            inputNodes.push(node)
        });

        const node = self.addNodeByNodeTypeName("entityWriter", 800, 500);
        node.configItemsByNames["templateId"].value = entityKeyId;
        self.editor.updateNodeInputs(node.id, []);
        self.editor.updateNodeInputs(node.id, inputsVars);
        this.connectNodeToInputs(node.id, inputNodes)
        
    }
    updateNodeInputs(nodeId, vars){
        this.editor.updateNodeInputs(nodeId, vars)
    }
    connectNodeToInputs(nodeId, inputNodes){
        const inputVarNameToId = {};
        inputNodes.forEach(x => {
          if(x.configItemsByNames.variableName && x.configItemsByNames.variableName.value){
            inputVarNameToId[x.configItemsByNames.variableName.value] = x.id
          }
         })
        this.editor.connectNodeToInputs(nodeId, inputVarNameToId)
    }

    addNodeByNodeTypeName(name, pos_x = 0, pos_y = 0){
        let editor = this.editor;
        if (editor.editor_mode === "fixed") {
            return false;
        }
        pos_x = editor.precanvas.clientHeight ?
            (pos_x *
            (editor.precanvas.clientWidth /
                (editor.precanvas.clientWidth * editor.zoom)) -
            editor.precanvas.getBoundingClientRect().x *
            (editor.precanvas.clientWidth /
                (editor.precanvas.clientWidth * editor.zoom))) : pos_x;
        pos_y = editor.precanvas.clientHeight ? 
            (pos_y *
            (editor.precanvas.clientHeight /
                (editor.precanvas.clientHeight * editor.zoom)) -
            editor.precanvas.getBoundingClientRect().y *
            (editor.precanvas.clientHeight /
                (editor.precanvas.clientHeight * editor.zoom))) : pos_y;

        if (!(name in this.nodeTypeById)) {
            return;
        }
        let nodeTypeToAdd = JSON.parse(JSON.stringify(this.nodeTypeById[name]));

        let newNode = editor.addNode(
            nodeTypeToAdd.nodeTypeTitle,
            nodeTypeToAdd.inputs,
            nodeTypeToAdd.outputs,
            pos_x,
            pos_y,
            "",
            { title: nodeTypeToAdd.nodeTypeTitle },
            "",
            "vue"
        );
        newNode.visibleText = nodeTypeToAdd.visibleText || false;
        newNode.configItems = nodeTypeToAdd.defaultNodeConfigItems;
        newNode.configItemsByNames = {};
        newNode.configItems.forEach(x=> {
            newNode.configItemsByNames[x.name] = x;
        })
        newNode.nodeType = nodeTypeToAdd.nodeType;
        newNode.nodeTypeObject = nodeTypeToAdd;
        newNode.description = nodeTypeToAdd.description;
        newNode.injectorFilePath = nodeTypeToAdd.injectorFilePath;
        newNode.icon = nodeTypeToAdd.icon;
        newNode.iconbordercolor = nodeTypeToAdd.iconbordercolor;
        newNode.backgroundcolor = nodeTypeToAdd.backgroundcolor;

        this.nodes.push(newNode);
        return newNode;
    }

    importNodes(nodes){
        let self = this;
        const toImport = {
            drawflow: {
              Home: {
                data: {},
              },
            },
        };
        nodes.forEach((x) => {
            toImport.drawflow["Home"]["data"][x.id] = x;
        });
        this.nodes = this.editor.import(toImport);
        let _this = this;
        this.nodes.forEach((x) => {

            if(x.configItems){
              x.configItemsByNames = {};
              x.configItems.forEach((y) => {
                x.configItemsByNames[y.name] = y;
              });
            }
            if(_this.nodeTypeById && _this.nodeTypeById[x["nodeType"]]){
              _this.nodeTypeById[x["nodeType"]].defaultNodeConfigItems.forEach((y, i) => {
                const cy = JSON.parse(JSON.stringify(y));
                if(x.configItemsByNames[cy.name]) {
                  x.configItemsByNames[cy.name] = {...cy, ...x.configItemsByNames[cy.name] }
                }else {
                  x.configItemsByNames[cy.name] = cy;
                  x.configItems.splice(i, 0, cy);
                }
              })
            }
            if (self.nodeTypeById[x.nodeType]) {
                x.nodeTypeObject = self.nodeTypeById[x.nodeType];
            }
        });



    }

    getInputsStructure() {
        let self = this;
        let inputs = [];
        let sortedNodes = this.nodes.filter(x=>x.nodeType == "inputNode")
        sortedNodes.sort((a, b) => (a.pos_y > b.pos_y ? 1 : -1));
  
        sortedNodes.forEach(function (inputNode) {
          let currentInput = { nodeId: inputNode.id };
          let configsMap = self.configListToMap(inputNode.configItems);
          currentInput.name = configsMap["variableName"];
          currentInput.type = configsMap["type"];
          currentInput.value = configsMap["defaultValue"];
          currentInput.hidden = configsMap["hidden"];
          currentInput.label = configsMap["label"];
          currentInput.configsMap = configsMap;
          
          if (
            currentInput.type == "input" ||
            currentInput.type == "textarea" ||
            currentInput.type == "formtag"
          ) {
            currentInput.placeholder = configsMap["placeholder"];
          }
  
          if (currentInput.type == "textarea") {
            currentInput.numberOfLines = configsMap["numberOfLines"];
          }
  
          if (
            currentInput.type == "formtag" ||
            currentInput.type == "checkboxlist" ||
            currentInput.type == "multiselect"
          ) {
            currentInput.maxSelections = configsMap["maxSelections"];
          }
  
          if (
            currentInput.type == "checkboxlist" ||
            currentInput.type == "multiselect"
          ) {
            currentInput.maxChars = configsMap["maxChars"];
          }
          if (
            currentInput.type == "select" ||
            currentInput.type == "multiselect"
          ) {
            currentInput.options = configsMap.options;
          }
  
          inputs.push(currentInput);
        });
        let sortedForNodes = this.nodes.filter(x=>x.nodeType == "forInputNode")
        sortedForNodes.sort((a, b) => (a.pos_y > b.pos_y ? 1 : -1));
        sortedForNodes.forEach((inputNode)=> {
          let currentInput = { nodeId: inputNode.id, type:"forInput" };
          let configsMap = self.configListToMap(inputNode.configItems);
          currentInput.name = configsMap["variableName"];
          currentInput.value = configsMap["defaultValue"];
          currentInput.label = configsMap["label"];
          currentInput.configsMap = configsMap;
          currentInput.items = [];

          configsMap["items"].forEach(function (outputItem) {
              let innerOutputItem = {};
              let innerOutputConfig = self.configListToMap(outputItem);
              innerOutputItem.type = innerOutputConfig["varType"];
              innerOutputItem.name = innerOutputConfig["variableName"];
              innerOutputItem.value = innerOutputConfig["defaultValue"];
              innerOutputItem.label = innerOutputConfig["label"];
              currentInput.items.push(innerOutputItem);
          });
          inputs.push(currentInput);
        });
        return inputs;
      }
      configListToMap(confList) {
        let res = {};
        confList.forEach(function (confItm) {
          if ("name" in confItm && ("value" in confItm || "items" in confItm)) {
            res[confItm["name"]] =
              "value" in confItm ? confItm["value"] : confItm["items"];
          }
        });
        return res;
      }
      getDynamicOutputsStructure() {
        let self = this;
        let outputs = [];
        const nodeById = {};
        this.nodes.forEach(x => {
          nodeById[x.id] = x;
        })
        let sortedNodes = this.nodes.filter(x=> x.nodeType == "dynamicOutputNode")
        sortedNodes.sort((a, b) => (a.pos_y > b.pos_y ? 1 : -1));
        sortedNodes.forEach(function (outputNode) {
              let currentOutput = { nodeId: outputNode.id };
              let configsMap = self.configListToMap(outputNode.configItems);
              const inputs = {};
              Object.keys(outputNode.inputs).forEach(l => {
                if(outputNode.inputs[l].connections && outputNode.inputs[l].connections[0]){
                  const dynamicOutputConnection = outputNode.inputs[l].connections[0];
                  const targetNode = nodeById[dynamicOutputConnection.node];
                  if(targetNode){
                    // const inConnection = targetNode.outputs[dynamicOutputConnection.input].connections.find(t => t.node == outputNode.id)
                    // if(inConnection){
                      inputs[l] = targetNode.configItemsByNames["variableName"].value
                    // }
                  }
                }
                
              })
              currentOutput.templateId = configsMap["templateId"];
              currentOutput.buttonActionName = configsMap["buttonActionName"];
              currentOutput.inputs = inputs;
              outputs.push(currentOutput);
            
        });
        return outputs;
      }
      getOutputsStructure() {
        let self = this;
        let outputs = [];
        const nodeById = {};
        this.nodes.forEach(x => {
          nodeById[x.id] = x;
        })
        let sortedNodes = this.nodes.filter(x=> x.nodeTypeObject && x.nodeTypeObject.category == "Outputs" || (x.nodeType == "subTemplateNode" && x.configItemsByNames["isOutput"] && x.configItemsByNames["isOutput"].value))
        sortedNodes.sort((a, b) => (a.pos_y > b.pos_y ? 1 : -1));
        for(let i=0; i<sortedNodes.length; i++){
          const outputNode = sortedNodes[i];
          if (outputNode.nodeType == "forNode" && outputNode.configItemsByNames && outputNode.configItemsByNames["isOutput"] && outputNode.configItemsByNames["isOutput"].value == false) {
            continue;
          }
          if (outputNode.nodeType == "subTemplateNode") {
            let configsMap = self.configListToMap(outputNode.configItems);
            let currentOutput = { nodeId: outputNode.id, type: "subTemplate", templateId: configsMap["templateId"], name: configsMap["variableName"], label: configsMap["label"] };
            outputs.push(currentOutput);
          }
          else if (outputNode.nodeType == "agent") {
            let configsMap = self.configListToMap(outputNode.configItems);
            let currentOutput = { nodeId: outputNode.id, type: "agentTemplate", name: configsMap["variableName"], label: configsMap["label"], structure: {} };
            outputs.push(currentOutput);
          }
          else if(outputNode.nodeType == "dynamicOutputNode"){
            let currentOutput = { nodeId: outputNode.id };
            let configsMap = self.configListToMap(outputNode.configItems);
            const inputs = {};
            Object.keys(outputNode.inputs).forEach(l => {
              if(outputNode.inputs[l].connections && outputNode.inputs[l].connections[0]){
                const dynamicOutputConnection = outputNode.inputs[l].connections[0];
                const targetNode = nodeById[dynamicOutputConnection.node];
                if(targetNode){
                  // const inConnection = targetNode.outputs[dynamicOutputConnection.input].connections.find(t => t.node == outputNode.id)
                  // if(inConnection){
                    inputs[l] = targetNode.configItemsByNames["variableName"].value
                  // }
                }
              }else {
                inputs[l] = "____________" + l;
              }
              
            })
            currentOutput.templateId = configsMap["templateId"];
            currentOutput.buttonActionName = configsMap["buttonActionName"];
            currentOutput.inputs = inputs;
            currentOutput.type = "dynamicOutput";
            outputs.push(currentOutput);
          }
          else if (outputNode.nodeType == "objectOutputNode") {
            let currentOutput = { nodeId: outputNode.id };
            let configsMap = self.configListToMap(outputNode.configItems);
            currentOutput.name = configsMap["objectName"];
            currentOutput._label = configsMap["label"];
            currentOutput.type = "multifield";
            currentOutput.items = [];
            currentOutput.documentation = configsMap["documentation"]
            configsMap["items"].forEach(function (outputItem) {
                let innerOutputItem = {};
                let innerOutputConfig = self.configListToMap(outputItem);
                innerOutputItem.type = innerOutputConfig["varType"];
                innerOutputItem.name = innerOutputConfig["variableName"];
                innerOutputItem.value = innerOutputConfig["defaultValue"];
                innerOutputItem.documentation = innerOutputConfig["documentation"]
                currentOutput.items.push(innerOutputItem);
            });

            outputs.push(currentOutput);
            } else {
                let currentOutput = { nodeId: outputNode.id };
                let configsMap = self.configListToMap(outputNode.configItems);
                currentOutput.name = configsMap["variableName"];
                currentOutput.type = configsMap["type"];
                currentOutput.value = configsMap["defaultValue"];
                currentOutput.label = configsMap["label"];
                outputs.push(currentOutput);
            }
        }

        return outputs;
      }

    getEditor(){
        return this.editor;
    }

    getNodes(){
        return this.nodes;
    }

    getNodeTypeById(){
        return this.nodeTypeById;
    }
    getNodeTypeList(){
        return this.nodeTypeList;
    }
    getSelectedNodeId(){
        return this.selectedNodeId;
    }



}
