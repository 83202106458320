<template>
  <b-modal
      id="modal-prevent-closing"
      ref="clone-modal"
      title="Enter Template Name"
      ok-title="Clone"
      centered
      cancel-variant="outline-secondary"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="nameState"
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :state="nameState"
            required
          />
        </b-form-group>
      </form>
    </b-modal>
</template>

<script>
import {
    BModal,
  BFormGroup,
  BFormInput
} from "bootstrap-vue";
export default {
    props: ["organizations"],
    components: {BFormInput, BModal, BFormGroup},
  data() {
    return {
        nameState: null,
        name: "",
        toClone: null
    }
  },
  methods: {
    showModal(toClone) {
      this.toClone = toClone;

      this.$refs["clone-modal"].show();
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      
      this.nameState = null;
      this.toClone = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
      this.resetModal();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.$emit('on-clone', {
        id: this.toClone.id,
        name: this.name,
      })
    

      this.$nextTick(() => {
        this.$refs["clone-modal"].toggle("#toggle-btn");
      });
    },
  },
};
</script>