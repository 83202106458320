import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchOrganizations(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/organizations', {params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrganization(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/organizations/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addOrganization(ctx, organizationData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/organizations', organizationData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editOrganization(ctx, { id, organizationData } ) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/organizations/${id}`, organizationData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
